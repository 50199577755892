import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import { jwtDecode } from "jwt-decode"; 
import Button from '@mui/material/Button';
import {v4} from 'uuid'
import { useFace } from './hooks';
import { UserInfo } from './user-info';
import { UserCard } from './user-card';
import { ModalWithIframe, getSessionDetailByEmail, getSessionDetailByPhone, token } from './modal';
import { Matching } from './match';
import { SelectedDeviceIdState, SessionDetailState, VideoHeightState, WebrtStatusState, WebrtcSessionIdState } from './states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PhoneNumberModal from './phone-modal';
import usePeerConnection from './hooks/connect-rtc';
import { FileUpload } from './file-upload';
import { isSafariBroswerDetect } from './utils';
import { Memory } from './memory';
import { CameraSelector } from './devices';
import { useAgoraWebcam } from 'hooks/agora';
import axios from 'axios';

const webcamClass = '_webcam'
const canvasClass = '_output_canvas'

async function getStreamToken(session_id: string): Promise<any> {
  const url = `https://demo-events.backendly.io/stream/token?session_id=${session_id}`;
  
  try {
    const response = await axios.get(url, {
      headers: {
        'accept': 'application/json'
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Failed to get stream token:', error);
    throw error;
  }
}

async function startEvent(
  session_id: string,
  img_url: string,
): Promise<any> {
  const mainUrl = `https://demo-events.backendly.io/stream/face/start`;
  
  const data = await getStreamToken(session_id);

  try {
    await axios.post(mainUrl, {
      session_id,
      img_url,
    }, {
      headers: {
        'accept': 'application/json',
      }
    });
    
    return data;
  } catch (error) {
    console.error('Failed to start event:', error);
    throw error;
  }
}

export function Demo() {
  const [number, setNumber] = useState('')
  const [label, setLabel] = useState("ENABLE WEBCAM");
  const {start} = useFace();
  const [webcamOpen, setWebcamOpen] = useState(false);
  const [open, setOpen] = useState(false); // State to manage modal visibility
  const setSessionDetail = useSetRecoilState(SessionDetailState);
  const [instanceId, setInstanceId] = useState(v4());
	const { createPeerConnection } = usePeerConnection();
  const selectedDeviceId = useRecoilValue(SelectedDeviceIdState);
  const videoHeight = useRecoilValue(VideoHeightState);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [isSessionLoading, setIsSessionLoading] = useState(false);

  const appId = '967725ed771648708a01ce0058e99b96';
  const setWebrtStatus = useSetRecoilState(WebrtStatusState);
  const setWebrtcSessionId = useSetRecoilState(WebrtcSessionIdState);

  const { publish, stopPublishing, initialize, cleanup, videoTrack } = useAgoraWebcam(appId);

  const handleOpenModal = () => {
    // setWebcamOpen(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const startConnection = useCallback(async (session: any) => {
    setWebrtStatus("Creating Connection...");
    setWebrtcSessionId(Memory.sessionId);
    Memory.connectionMade = true;
    const uid = v4();
    try {
      const imageUrl = 'https://storage.googleapis.com/satschel-assets-public/aus-selfie.png';
      // const imageUrl = session.authentication?.[0]?.data.data.selfieImage ?? "https://storage.googleapis.com/satschel-kyc/pipelines.api/stage/sessions/authentication/665e2463eb91c673e461a152/665e2463eb91c673e461a160/e909a6ba-ff8b-4454-b76d-30a415ed7b67.png?GoogleAccessId=nich-service-account%40glossy-fastness-305315.iam.gserviceaccount.com&Expires=1742169600&Signature=Xpen9GxI2QMqjefSqvk4uaJbaJCAoTV1igKUDE6JS1JKEeD3yYKe1G1Q%2FtEA8gBNHMNYZd1MwA4hcEBYtwAqFEHUF7Hq1AovGZk9eailYwVqVhUfzXC%2BGq9aAptnm3WpcbSW%2Byjof36in5mkQUdiAWMSCk2v16J35bVFsFC8VMdBVdAyT6UIPjejSxiCE2MI8%2Bjp49AjjZU8739th3fC9J%2BLsn9nWp9SNyw1rjFjJVNoKiJg3cgcfZpA8CXPfwRtsqyotYb63qHnc%2FkjhTiJ99pHR4bhxk2pRJxo5zWbCpjkokzNcMXZXNFKNTIK0AzVNRYYLgKoxDtMt8sWlovRbw%3D%3D";
      const data = await startEvent(Memory.sessionId, imageUrl);
      const token = data.token;
      // setToken(token);
      initialize(token, Memory.sessionId, uid, selectedDeviceId).then(async (track) => {
        if (track) {
          // setStart(true);
          await publish(track);
        }
        setWebrtStatus("Waiting for match...");
      })
    } catch (error) {
      console.error('Failed to start connection:', error);
      setWebrtStatus("Failed to start connection");
      // throw error;
    }
  }, [initialize, publish, selectedDeviceId])


  const onResults = useCallback((results?: FaceLandmarkerResult) => {
    // 
  }, [])

  const onCam = useCallback(() => {
    const videoBlendShapes = document.getElementById("video-blend-shapes");
    const video = document.getElementById(webcamClass) as HTMLVideoElement;
    const canvasElement = document.getElementById(
      canvasClass
    ) as HTMLCanvasElement;
    setLabel("ENABLE WEBCAM")
    start({
        video,
        canvas: canvasElement,
        blendShapes: videoBlendShapes,
        deviceId: selectedDeviceId,
        onResults
    }).then(() => {
      setWebcamOpen(true);
    });
  }, [onResults, selectedDeviceId, start])

  const handleSubmitPhoneNumber = async (number: string) => {
    // setPhoneNumber(number);
    setIsSessionLoading(true);
    setIsModalOpen(false);
    console.log('Submitted phone number:', number);
    try {
      const session = await getSessionDetailByPhone(number);
      setIsSessionLoading(false);
      if (session) {
        if (!Memory.connectionMade) {
          // TODO: remove it when storage fixed;
          // createPeerConnection(session);
          startConnection(session);
          // const url = session.authentication?.[0]?.data.data.selfieImage ?? "https://storage.googleapis.com/satschel-kyc/pipelines.api/stage/sessions/authentication/665e2463eb91c673e461a152/665e2463eb91c673e461a160/e909a6ba-ff8b-4454-b76d-30a415ed7b67.png?GoogleAccessId=nich-service-account%40glossy-fastness-305315.iam.gserviceaccount.com&Expires=1742169600&Signature=Xpen9GxI2QMqjefSqvk4uaJbaJCAoTV1igKUDE6JS1JKEeD3yYKe1G1Q%2FtEA8gBNHMNYZd1MwA4hcEBYtwAqFEHUF7Hq1AovGZk9eailYwVqVhUfzXC%2BGq9aAptnm3WpcbSW%2Byjof36in5mkQUdiAWMSCk2v16J35bVFsFC8VMdBVdAyT6UIPjejSxiCE2MI8%2Bjp49AjjZU8739th3fC9J%2BLsn9nWp9SNyw1rjFjJVNoKiJg3cgcfZpA8CXPfwRtsqyotYb63qHnc%2FkjhTiJ99pHR4bhxk2pRJxo5zWbCpjkokzNcMXZXNFKNTIK0AzVNRYYLgKoxDtMt8sWlovRbw%3D%3D";
          // @TODO: remove it when storage fixed;
          // const url = 'https://storage.googleapis.com/satschel-assets-public/aus-selfie.png';
          // const response = await fetch(url);
          // const blob = await response.blob();
          // Memory.selfieBinary = await blob.arrayBuffer();
        }
        if (/austin/gi.test(session.userInfo.name)) {
          if (localStorage.getItem("use_match")) {
            localStorage.setItem('match', 'true')
          }
        }
        console.log('session', session);
        setSessionDetail(session);
      }
    } catch(err) {
      setIsSessionLoading(false);
      console.log("err", err);
    }
    onCam();
  };

  useEffect(() => {
    const redirectURI = window.location.origin;
    (window as any).AppleID.auth.init({
      clientId: "com.satschel.stage.liveness.client", // e.g., com.example.app
      scope: "name email",
      redirectURI: redirectURI,
      state: "state",
      usePopup: true,
    });
  }, [])

  const isApple = isSafariBroswerDetect;
  // const isApple = false

  const onClick = useCallback(async() => {
    if (isApple) {
       (window as any).AppleID.auth.signIn()
        .then(async(response: any) => {
          console.log("response", response);
          const { authorization } = response;
          const { code, id_token } = authorization;
          const { email  = 'ddsdad@gmail.com' } = jwtDecode(id_token) as any;
          if (email){
              setIsSessionLoading(true);
              setIsModalOpen(false);
              console.log("Submitted phone number:", number);
              try {
                const session = await getSessionDetailByEmail(email);
                setIsSessionLoading(false);
                if (session) {
                  if (!Memory.connectionMade) {
                    createPeerConnection(session);
                  }
                  console.log("session", session);
                  setSessionDetail(session);
                }
              } catch (err) {
                setIsSessionLoading(false);
                console.log("err", err);
              }
          }
          onCam();
        })
        .catch((error: any) => {
          console.error("Apple Sign In Error:", error);
        });
    } else {
      handleOpenModal();
    }
  }, [createPeerConnection, isApple, number, onCam, setSessionDetail])

  const Buttons = useCallback(() => {
    return (
      <Grid container>
        <Grid item sm={4}>
        <Button onClick={() => {
          setOpen(true)
          setInstanceId(v4())
        }} className="mdc-button mdc-button--raised" variant="outlined">
            <span className="mdc-button__ripple"></span>
            <span className="mdc-button__label">Enroll Face & Palm</span>
        </Button>
        </Grid>

        <Grid item sm={4}>
          <CameraSelector />
        </Grid>
        <Grid item sm={4}>
        {isSessionLoading ? 
          <CircularProgress style={{float: "right"}} /> 
          : 
          <Button style={{float: "right"}} onClick={onClick} id="webcamButton" className="mdc-button mdc-button--raised" variant="contained">
              <span className="mdc-button__ripple"></span>
              <span className="mdc-button__label">{label}</span>
          </Button>
        }
        </Grid>
      </Grid>
    )
  }, [isSessionLoading, label, onClick]);

  const Blends = useCallback(() => {
    return (
      <>
        <div className="blend-shapes">
          <ul className="blend-shapes-list" id="video-blend-shapes"></ul>
        </div>
        {localStorage.getItem("register") ? <div id="register-form">
          <div>Register</div>
          <input placeholder='number' onChange={(e) => setNumber(e.target.value)} />
          <button onClick={() => {
            localStorage.setItem("phone", number)
          }}>Submit</button>
          <button onClick={() => {
            localStorage.removeItem("phone")
          }}>Reset</button>
        </div> : null}
      </>
    )
  }, [number])


  const Camera = useCallback(() => {
    return (
      <>
        <div>
          <div id="liveView" className="videoView">
            <div style={{
              position: "relative"
            }}>
              <video id={webcamClass} style={{
                position: "absolute"
              }} autoPlay playsInline></video>
              <canvas className={canvasClass} id={canvasClass} style={{
                position: 'absolute',
                left: 0,
                top: 0
              }}></canvas>
            </div>
          </div>

          <div>
            <img alt='' id="palm" src="" style={{display: 'none'}} />
          </div>
        </div>
      </>
    )
  }, [])

  return (
    <>
    { open ? <ModalWithIframe instanceId={instanceId} setOpen={setOpen} open={open} /> : null }
    <PhoneNumberModal
      open={isModalOpen}
      onClose={handleCloseModal}
      onSubmit={handleSubmitPhoneNumber}
    />
    <Grid container spacing={2}>
      <Grid className='left-section' item sm={6}>
        <Grid minHeight={videoHeight + 100} item>
          <Buttons />
          <div>
            <Camera />
          </div>
        </Grid>
        {
          webcamOpen ?  
          <Grid item>
            <Matching />
            <UserInfo />
          </Grid> : null
        }
      </Grid>
      <Grid item sm={6}>
        <FileUpload />
        <Grid item>
          <Blends />
        </Grid>
        <Grid item>
          <UserCard />
        </Grid>
      </Grid>
    </Grid>
    </>
  );
}
